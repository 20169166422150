import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css'

export default class Features extends Component {
    render() {
        return (
            <section id="our-project" className="sections">
                <Container>
                        <div className="heading" align="center">
                            <h4 className="our-history" align="center">PRODUCT FEATURES</h4>
                            <div className="main-project text-center">
                            <Row>
                                <Col md={3} sm={6} xs={12}>
                                    <div className="project-item">
                                        <div className="project-photo"><img src="images/projects/Untitled-1.png" alt="" /></div>
                                        <h5>Both English and Marathi versions are available</h5>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12}>
                                    <div className="project-item">
                                        <div className="project-photo"><img src="images/projects/cloud.png" alt="" /></div>
                                        <h5>Cloud Based Access</h5>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12}>
                                    <div className="project-item">
                                        <div className="project-photo"><img src="images/projects/custom.png" alt="" /></div>
                                        <h5><b>Customizable</b> according to specific requirements</h5>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12}>
                                    <div className="project-item">
                                        <div className="project-photo"><img src="images/projects/role.png" alt="" /></div>
                                        <h5>Role based access control for principal/staff/teachers etc.</h5>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12}>
                                    <div className="project-item" >
                                        <div className="project-photo"><img src="images/projects/unlimited users.png" alt="" /></div>
                                        <h5>Unlimited number of users</h5>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12}>
                                    <div className="project-item">
                                        <div className="project-photo"><img src="images/projects/security.png" alt="" /></div>
                                        <h5>High security</h5>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12}>
                                    <div className="project-item">
                                        <div className="project-photo"><img src="images/projects/excel.png" alt="" /></div>
                                        <h5>Export records in excel  format</h5>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12}>
                                    <div className="project-item">
                                        <div className="project-photo"><img src="images/projects/user .png" alt="" /></div>
                                        <h5>User friendly design</h5>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12}>
                                    <div className="project-item">
                                        <div className="project-photo"><img src="images/projects/automatic.png" alt="" /></div>
                                        <h5>Automatic data population</h5>
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={12}>
                                    <div className="project-item"  >
                                        <div className="project-photo"><img src="images/projects/money.png" alt="" /></div>
                                        <h5>Salary and Fees Module</h5>
                                    </div>
                                </Col>
                                </Row>
                            </div>
                        </div>
                </Container>
            </section>
        );
    }
}
