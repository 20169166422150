import React, { Component } from 'react';
import {Container, Row, Col, Carousel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css'

export default class Home extends Component {
  render() {
    return (
        <header id="home" className="sections">
            <Container>
                    <div className="homepage-style">
                        <div className="top-arrow hidden-xs text-center"><img src="images/top-arrowwh.png" alt="" /></div>
                        <Row>
                        <Col md={6} sm={6} xs={12}>
                            <div className="unique-apps">
                                <h2>Let's take a step <br/>towards digital India </h2>
                                <p>
                                    It is a complete end-to-end school management software<br/> 
                                    Provides smooth flow in the on going activities of the school<br/>
                                    Customizable and Affordable<br/>
                                    Cloud Based access<br/>
                                    Free Demo and training to staff<br/>
                                    24*7 Techincal assistance 
                                </p>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={12}>
                                <Carousel controls={false}>
                                    <Carousel.Item>
                                        <img alt="900x500" src="images/slider-img.png" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img alt="900x500" src="images/slider-img.png" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img alt="900x500" src="images/slider-img.png" />
                                    </Carousel.Item>
                                </Carousel>
                        </Col>
                        </Row>
                    </div>
            </Container>
        </header>
    );
}
}
