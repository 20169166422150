import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css'

export default class Contact extends Component {
    render() {
        return (
            <section id="hire-us" className="sections">
                <Container>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <div className="hire-content">
                                <div className="img-overlay">
                                    <div className="creative-works">
                                        <h3>NEED SOFTWARE SOLUTIONS?</h3>
                                        <button className="btn hire-btn btn-primary">CONTACT US</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container> 
            </section>
        );
    }
}
