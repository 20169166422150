import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css'

export default class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="footer">
                <Container>
                    <Row>
                        <Col>
                            <div className="socio-copyright">
                                <div className="social">
                                    <a target="_blank" href="http://www.facebook.com" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
                                    <a target="_blank" href="http://www.facebook.com" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a>
                                    <a target="_blank" href="http://www.facebook.com" rel="noopener noreferrer"><i className="fa fa-google-plus"></i></a>
                                    <a target="_blank" href="http://www.facebook.com" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}