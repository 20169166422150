import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css'

export default class Advantages extends Component {
    render() {
        return (
            <section id="our-feature" className="sections">
                <Container>
                    <div className="heading" align="center">
                        <h4 className="our-history" align="center">OUR ADVANTAGES</h4>
                    </div>
                    <div className="feature-wrapper">
                        <Row>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Saves Time</span></h4>
                                    <p> With this saving of time teachers will have more time to concentrate on other important work. All sort of result analysis will be done automatically.</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Easy Search</span></h4>
                                    <p>Any sort of data will be found out with utmost ease like no. of girls, no. of girls in a particular class, no. of boys in a class, caste wise number, religion wise number, etc.</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Smart Calculations</span></h4>
                                    <p>Teachers will be saved of the cumbersome work of preparing attendance sheets, attendance reports, doing all the calculations to prepare the  mark sheets of various examinations and final progress card.</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content "> 
                                    <h4><i className="fa fa-database"></i><span>Ready Information</span></h4>
                                    <p>The office staff will have all the information at their fingertips prepared by computer quickly with 100% accurate information</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Accurate Data for Government</span></h4>
                                    <p>Information to be submitted to the Govt. offices will be done faster and accurate</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Modular approach</span></h4>
                                    <p>It has a modular approach &is an integrated system with many key features. This approach avoids duplication of work and results in time and cost savings.</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Certificate Generation</span></h4>
                                    <p>Leaving Certificate <br/>
                                    Bonafide Certificate <br/>
                                    Nirgam Certificate<br/>
                                    Caste Certificate<br/>
                                    </p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Student ID CARD</span></h4>
                                    <p>Teachers will be saved of the cumbersome work of preparing attendance sheets, attendance reports, doing all the calculations to prepare the  mark sheets of various examinations and final progress card.</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>General Register</span></h4>
                                    <p>The office staff will have all the information at their fingertips stored in the General Register</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Employee Database</span></h4>
                                    <p>Total information of staff along with photo. One can search the employees on the basis of designation, employee Id, sex, name, caste, category, religion, qualification category etc.</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Examination</span></h4>
                                    <p>Marks entry Processing of marks Unit tests mark sheet Term exam mark sheet Final Progress card</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Attendance Records</span></h4>
                                    <p>Storing, procressing and analysing of the attendance records would be done hassle free</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Library</span></h4>
                                    <p>Details of the available books Search of book on the basis of book title, author, price, edition, date of purchase etc.</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Fees and Salary</span></h4>
                                    <p>Fees and salary modules will be developed as per the need of the customers</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <div className="features-content"> 
                                    <h4><i className="fa fa-database"></i><span>Customization</span></h4>
                                    <p>Any additions, deletions will be done as per the need of the client.</p>
                                </div>
                            </Col>
                        </Row>	
                    </div>		
                </Container>
            </section>
        );
    }
}
