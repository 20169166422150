import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css'

export default class Menu extends Component {
    render() {
        return (
            <Navbar bg="default" sticky="top" expand="md">
                <Navbar.Brand>
                    <Link to="/"><img src="images/weblogo.png" alt="GRA Softwares" /></Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <Nav.Item>
                            <Nav.Link href="#home">Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#our-portfolio">About Us</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#our-project">Features</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#our-feature">Advantages</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#works-area">How we Work</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#hire-us">Contact Us</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}