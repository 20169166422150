import React, { Component } from 'react';
import {Button, Container} from 'react-bootstrap';
import Menu from './components/Menu';
import Home from './components/Home';
import About from './components/About';
import Features from './components/Features';
import Advantages from './components/Advantages';
import Work from './components/Work';
import Contact from './components/Contact';
import Footer from './components/Footer';

import './css/font-awesome.css';
export default class App extends Component {
  render() {
    return (
      <Container>
        <Menu/>
        <Home/>
        <About/>
        <Features/>
        <Advantages/>
        <Work/>
        <Contact/>
        <Button href="#home" >
          <div className="scroll-top">
              <div className="scrollup">
                  <i className="fa fa-angle-double-up"></i>
              </div>
          </div>
        </Button>
        <Footer/>
      </Container>
    );
  }
}