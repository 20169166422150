import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css'

export default class About extends Component {
    render() {
        return (
            <section id="our-portfolio" className="sections">
                <Container>
                        <div className="heading" align="center">
                        <h4 className="our-history" align="center">ABOUT US</h4>
                        </div>	
                        <div className="portfolio-wrap">
                            <div className="portfolio">
                            <Row>
                                <Col md={6} sm={6} xs={12}>
                                    <img src="images/monitor.png" alt="" />
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                    <div className="portfolio-item">
                                        <h4> ABOUT US </h4>
                                        <p>
                                            The schools are engaged in the noble cause of shaping the future of our country. While carrying out their main job of imparting the values and knowledge to the students, the staff has to do lot of other unproductive work which is mostly repetitive in nature and leads to shear wastage of precious time, money and energy. If the staff is made free from this sort of meaningless work, they could spare that time for the betterment of themselves, students, the organization and the society as a whole. 
                                        </p>
                                    </div>
                                </Col>
                                </Row>
                            </div>
                            <div className="portfolio">
                            <Row>
                                <Col md={6} sm={6} xs={12}>
                                    <div className="portfolio-item">
                                        <h4>OUR VISION</h4>
                                        <p>
                                            GRA Software Solutions & Trainers has realized the gravity of this problem and has developed a simple,  user-friendly software - School Management System (sms). With the use of this software, the school staff, be it administrative or academic, will save 80 to 100% of their valuable time to use it for other concrete, meaningful and productive activities.                                    </p>
                                    </div>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                    <img className="portfolio-img img-responsive" src="images/image-05012017-115255.jpg" alt="" />
                                </Col>
                                </Row>
                            </div>
                        </div>
                </Container>
            </section>
        );
    }
}