import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css'

export default class Work extends Component {
    render() {
        return (
            <section id="works-area" className="sections">
                <Container>
                    <div className="heading" align="center">
                        <h4 className="our-history" align="center">HOW WE WORK</h4>
                    </div>	
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                                <img  src="images/map2.png" alt="" />
                        </Col>
                    </Row>	
                </Container>
            </section>
        );
    }
}